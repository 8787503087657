import http from '@/utils/http'
class Api {
  /*
  * create下单
  */
  create = (data: any) => {
    return http.post('/channel/lvepoint/create', data)
  }
}
export default new Api()
