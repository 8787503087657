
import { Options, Vue } from 'vue-class-component'
import { toDate } from '../utils/common'
import { Dialog } from 'vant'
import api from '@/api/index'
@Options({
  components: {}
})
export default class Home extends Vue {
  toDate = toDate;
  guaranteetype = '日'
  loading = false

  model = {
    projectname: '',
    bondname: '履约保函',
    lettertype: '担保保函',
    outorg: '',
    zbr: '',
    zbrorgnum: '',
    danweiname: '',
    unitorgnum: '',
    lettermoney: '',
    guarantee: '',
    contacts: '',
    mobile: '',
    referee: '',
    source: '中联银公众号'
  };

  changeORG () {
    this.model.outorg = ''
    if (this.model.lettertype === '银行保函') {
      this.columns = ['中国建设银行', '华商银行', '青岛农商行']
      return
    }
    this.columns = ['深圳中联银融资担保']
  }

  showPicker = false;
  columns:any = [];
  onConfirm (value: any) {
    this.showPicker = false
    this.model.outorg = value
  }

  goOK () {
    if (!this.model.projectname) {
      Dialog.alert({
        title: '温馨提示',
        message: '请输入项目名称'
      })
      return
    }
    if (!this.model.outorg) {
      Dialog.alert({
        title: '温馨提示',
        message: '请选择出函机构'
      })
      return
    }
    if (!this.model.zbr) {
      Dialog.alert({
        title: '温馨提示',
        message: '请输入受益人名称'
      })
      return
    }
    if (!this.model.danweiname) {
      Dialog.alert({
        title: '温馨提示',
        message: '请输入被保证人名称'
      })
      return
    }
    if (!this.model.lettermoney) {
      Dialog.alert({
        title: '温馨提示',
        message: '请输入担保金额'
      })
      return
    }
    if (!this.model.guarantee) {
      Dialog.alert({
        title: '温馨提示',
        message: '请输入担保期限'
      })
      return
    }
    if (!this.model.contacts) {
      Dialog.alert({
        title: '温馨提示',
        message: '请输入联系人'
      })
      return
    }
    if (!this.model.mobile) {
      Dialog.alert({
        title: '温馨提示',
        message: '请输入联系方式'
      })
      return
    }
    this.loading = true
    const _data = { ...this.model, guarantee: this.model.guarantee + this.guaranteetype }
    api.create(_data).then(data => {
      this.$router.push('/success')
    }).finally(() => {
      this.loading = false
    })
  }

  created () {
    this.changeORG()
  }
}
